
































import {Component, Vue} from 'vue-property-decorator';
import HeaderBar from "@/components/HeaderBar.vue";
import {actionStringWork, Category, IWork} from "@/store/work";
import {Action} from "vuex-class";
import {category} from "@/Types/Types";
import Loader from "@/components/loader.vue";
import FooterBar from "@/components/FooterBar.vue";
import FooterMain from "@/components/FooterMain.vue";
import { actionStringPeople, IPeople } from "@/store/people";

interface IPeople{
  image:string,
  name:string,
  profession:string,
  description:string,
}

@Component({
  components: {FooterMain, FooterBar, Loader, HeaderBar},
})

export default class People extends Vue {
  activeIndex:number | null = null;
  activePeople:IPeople | null = null;
  people: Array<IPeople> | null = null;
  
  @Action(actionStringPeople.GET_PEOPLE) getPeople: (() => Promise<any[]>) | undefined;

  hideMenu(): void {
    this.activeIndex = null;
    this.activePeople = null;
  }

  showModal(people:IPeople, index:number):void{
    this.activeIndex = index;
    this.activePeople = people;
  }

  created(): void {
    // fetch people list
    this.getPeople().then(people => {
        this.people = people
      }).catch(err => {
        alert('Error fetching people', err )
      })
  }
}

